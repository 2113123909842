.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.footer {
  position: fixed;
  width: 100%;
  bottom: 0;
 }

 .mobile-navbar {
  justify-content: center !important; 
 }

 .required:after{
  content:"*";
  font-weight:bold;
  color: red;
}

.myDate {
  -webkit-appearance: none;
    appearance: none;
    background-clip: padding-box;
    background-color: #fff;
    background-color: var(--bs-form-control-bg);
    border: 1px solid #dee2e6;
    border: var(--bs-border-width) solid var(--bs-border-color);
    border-radius: 0.375rem;
    color: #212529;
    color: var(--bs-body-color);
    display: block;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    padding: 0.375rem 0.75rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    width: 100%;
}
